<template>
  <div ref="target" class="shop-product-renderer">
    <ShopProductCard
      v-for="(item, index) in products"
      :key="`${index}${item.shopItemId}`"
      :item="item"
    />
  </div>
</template>

<script setup>
import { useElementVisibility } from '@vueuse/core';

const props = defineProps({
    products: {
        type: Array,
        default: () => [],
    },
});

const isViewSent = ref(false);
const clientDeviceStore = useClientDeviceStore();
const scrolledPercentage = computed(() => clientDeviceStore.scrollPositionPercentage);
const { sendEvent } = useGTM();

const target = ref(null);

watch(() => scrolledPercentage.value, () => {
    const targetIsVisible = useElementVisibility(target);
    if (targetIsVisible.value === true && isViewSent.value === false) {
        sendEvent({
            event: 'view_item_list',
            ecommerce: {
                currency: 'CHF',
                value: null,
                items: props.products.map((product) => ({
                    id: product.id,
                    name: product.name,
                    pharma_code: product.pharma_code,
                    price: product.price,
                    is_sendable: product.is_sendable || false,
                    needs_prescription: product.needs_prescription || false,
                    short_text: product.short_text,
                    description: product.description,
                })),
            },
        });
        isViewSent.value = true;
    }
});
</script>

<style lang="scss" scoped>
.shop-product-renderer {
    @include grid-layout();
    @include grid-columns(1, var(--grid-columns));
    @include fluid('margin-bottom', 20px, 40px);

    width: 100%;
    max-width: var(--grid-width);
    row-gap: var(--grid-gutter);

    .has-one-item & {
        grid-template-columns: 1fr;
    }
}
</style>
